export { matchers } from "/.svelte-kit/generated/client/matchers.js";

export const nodes = [
	() => import("/.svelte-kit/generated/client/nodes/0.js"),
	() => import("/.svelte-kit/generated/client/nodes/1.js"),
	() => import("/.svelte-kit/generated/client/nodes/2.js"),
	() => import("/.svelte-kit/generated/client/nodes/3.js"),
	() => import("/.svelte-kit/generated/client/nodes/4.js"),
	() => import("/.svelte-kit/generated/client/nodes/5.js"),
	() => import("/.svelte-kit/generated/client/nodes/6.js"),
	() => import("/.svelte-kit/generated/client/nodes/7.js"),
	() => import("/.svelte-kit/generated/client/nodes/8.js"),
	() => import("/.svelte-kit/generated/client/nodes/9.js"),
	() => import("/.svelte-kit/generated/client/nodes/10.js"),
	() => import("/.svelte-kit/generated/client/nodes/11.js"),
	() => import("/.svelte-kit/generated/client/nodes/12.js"),
	() => import("/.svelte-kit/generated/client/nodes/13.js"),
	() => import("/.svelte-kit/generated/client/nodes/14.js"),
	() => import("/.svelte-kit/generated/client/nodes/15.js"),
	() => import("/.svelte-kit/generated/client/nodes/16.js"),
	() => import("/.svelte-kit/generated/client/nodes/17.js"),
	() => import("/.svelte-kit/generated/client/nodes/18.js"),
	() => import("/.svelte-kit/generated/client/nodes/19.js"),
	() => import("/.svelte-kit/generated/client/nodes/20.js"),
	() => import("/.svelte-kit/generated/client/nodes/21.js"),
	() => import("/.svelte-kit/generated/client/nodes/22.js"),
	() => import("/.svelte-kit/generated/client/nodes/23.js"),
	() => import("/.svelte-kit/generated/client/nodes/24.js"),
	() => import("/.svelte-kit/generated/client/nodes/25.js"),
	() => import("/.svelte-kit/generated/client/nodes/26.js"),
	() => import("/.svelte-kit/generated/client/nodes/27.js"),
	() => import("/.svelte-kit/generated/client/nodes/28.js"),
	() => import("/.svelte-kit/generated/client/nodes/29.js"),
	() => import("/.svelte-kit/generated/client/nodes/30.js"),
	() => import("/.svelte-kit/generated/client/nodes/31.js"),
	() => import("/.svelte-kit/generated/client/nodes/32.js"),
	() => import("/.svelte-kit/generated/client/nodes/33.js"),
	() => import("/.svelte-kit/generated/client/nodes/34.js"),
	() => import("/.svelte-kit/generated/client/nodes/35.js")
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [6,[2]],
		"/(app)/admin": [7,[2,3]],
		"/(app)/admin/evaluations": [8,[2,3]],
		"/(app)/admin/functions": [9,[2,3]],
		"/(app)/admin/functions/create": [10,[2,3]],
		"/(app)/admin/functions/edit": [11,[2,3]],
		"/(app)/admin/settings": [12,[2,3]],
		"/(app)/admin/users": [13,[2,3]],
		"/auth": [32],
		"/(app)/c/[id]": [14,[2]],
		"/error": [33],
		"/(app)/playground": [15,[2,4]],
		"/(app)/playground/completions": [16,[2,4]],
		"/(app)/playground/notes": [17,[2,4]],
		"/s/[id]": [34],
		"/watch": [35],
		"/(app)/workspace": [18,[2,5]],
		"/(app)/workspace/functions/create": [19,[2,5]],
		"/(app)/workspace/knowledge": [20,[2,5]],
		"/(app)/workspace/knowledge/create": [22,[2,5]],
		"/(app)/workspace/knowledge/[id]": [21,[2,5]],
		"/(app)/workspace/models": [23,[2,5]],
		"/(app)/workspace/models/create": [24,[2,5]],
		"/(app)/workspace/models/edit": [25,[2,5]],
		"/(app)/workspace/prompts": [26,[2,5]],
		"/(app)/workspace/prompts/create": [27,[2,5]],
		"/(app)/workspace/prompts/edit": [28,[2,5]],
		"/(app)/workspace/tools": [29,[2,5]],
		"/(app)/workspace/tools/create": [30,[2,5]],
		"/(app)/workspace/tools/edit": [31,[2,5]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from "/.svelte-kit/generated/root.svelte";